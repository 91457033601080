<template>
  <div>
    <v-card elevation="1">
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
      </v-card-actions>
      <div class="two-factor-auth">
        <h3>Two-Factor Authentication</h3>
        <div class="spacer"></div>
        <v-row><p class="text-info">
          Note: At least one authentication Verification is required for security purposes.
        </p></v-row>
        <v-row>
          <loading :active="isLoading" :loader="loader" />
          <!-- Authenticator App Section -->
          <v-col cols="6">
            <h4>Authenticator App:</h4>
            <div class="spacer"></div>
            <div class="v-slide-group__content"></div>
            <div class="spacer"></div>
            <p>
              Authenticator app status:
              <span :class="authenticatorEnabled ? 'text-success' : 'text-danger'">
                {{ authenticatorEnabled ? 'Enabled' : 'Disabled' }}
              </span>
            </p>
            <!-- Toggle Switch -->
            <v-switch
              v-model="authenticatorEnabled"
              :label="authenticatorEnabled ? 'Enabled' : 'Disabled'"
              color="success"
              inset
              @change="handleAuthenticatorToggle"
            />
            <div class="spacer"></div>
            <p v-if="showAppLink">
             Authenticator APP Verification is enabled on your account.
            </p>
            <p v-if="!showAppLink">
              Receive a verification code on the Authenticator app (Android, iOS, or Blackberry).
              Please install this app on your mobile device before continuing.
            </p>
            <div v-if="!showAppLink" class="app-download-buttons">
              <a
                href="https://apps.apple.com/ua/app/google-authenticator/id388497605"
                target="_blank"
                class="tooltip">
                <img
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="Download on the App Store"
                  width="150"
                />
                <span class="tooltiptext">
                  Install Google Authenticator from App Store
                </span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1"
                target="_blank"
                class="tooltip">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="Get it on Google Play"
                  width="150"
                />
                <span class="tooltiptext">
                  Install Google Authenticator from Play Store
                </span>
              </a>
            </div>
            <div class="spacer"></div>
            <v-row v-if="showVerification">
              <v-col cols="6">
                <p>
                  Please scan the QR code using your authenticator app and enter the verification code displayed by this app.
                </p>
               <v-text-field
                  v-model="verificationCode"
                  :rules="verificationCodeRules"
                  label="Enter verification code"
                  outlined
                  dense
                  maxlength="6"
                  counter="6"
                ></v-text-field>
                <v-btn
                  color="success"
                  :disabled="!isVerificationCodeValid"
                  @click="verifyGoogleAuthenticationCode"
                >
                  Verify
                </v-btn>
                <!-- <v-btn color="success" @click="verifyGoogleAuthenticationCode">Verify</v-btn> -->
              </v-col>
              <v-col cols="6">
                <div class="qr-code-container">
                  <!-- Display dynamic QR Code -->
                  <img
                    v-if="partyAuthenticationDetail.qrCode"
                    :src="'data:image/png;base64,' + partyAuthenticationDetail.qrCode"
                    alt="QR Code"
                  />
                  <p v-else>No QR code available</p>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="disabledGooglAuth">
              <v-col cols="6">
                <p>
                  Please enter the verification code to disable the Authenticator App displayed by Google Authenticator App.
                </p>
               <v-text-field
                  v-model="verificationCode"
                  :rules="verificationCodeRules"
                  label="Enter verification code"
                  outlined
                  dense
                  maxlength="6"
                  counter="6"
                ></v-text-field>
                <v-btn 
                   color="success"
                  :disabled="!isVerificationCodeValid"
                  @click="disabledGoogleAuthentication"
                  >
                  Disable & Verify
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <h4>Email Verification:</h4>
            <div class="spacer"></div>
            <div class="v-slide-group__content"></div>
            <div class="spacer"></div>
            <p>
              Email verification status:
              <span :class="emailEnabled ? 'text-success' : 'text-danger'">
                {{ emailEnabled ? 'Enabled' : 'Disabled' }}
              </span>
            </p>
            <!-- Toggle Switch -->
            <v-switch
              v-model="emailEnabled"
              :label="emailEnabled ? 'Enabled' : 'Disabled'"
              color="success"
              inset
              @change="handleEmailAuthenticatorToggle"
            ></v-switch>
            <div class="spacer"></div>
            <p v-if="emailAuthenticationMsg">
              {{emailAuthenticationMsgDetal}}
            </p>
            <!-- Show email verification form when email authentication is enabled -->
            <div v-if="showEmailVerification">
              <!-- Email address and Submit button on one line -->
              <v-row align="center" class="mb-3">
                <v-col cols="9">
                  <v-text-field
                    label="Email address to which login verification codes should be sent"
                    v-model="emailAddress"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                 <v-btn color="primary" 
                    :disabled="isButtonDisabled"
                    @click="sendEmailVerificationCode">
                      {{ isButtonDisabled ? `Wait ${formattedTime}` : "Send" }}     
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Verification code and Verify button on one line -->
              <v-row align="center">
                <v-col cols="9">
                    <v-text-field
                      label="Enter verification code"
                      v-model="emailVerificationCode"
                      :rules="emailVerificationCodeRules"
                      outlined
                      dense
                      maxlength="6"
                      counter="6"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <v-btn 
                    color="success" 
                    :disabled="!isEmailVerificationCodeValid()" 
                    @click="verifyEmailAuthenticationCode">
                    Verify
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="disabledEmailAuth">
              <!-- Email address and Submit button on one line -->
              <v-row align="center" class="mb-3">
                <v-col cols="9">
                  <v-text-field
                    label="Email address to which login verification codes should be sent"
                    v-model="emailAddress"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                   <v-btn color="primary" 
                    :disabled="isButtonDisabled"
                    @click="sendEmailVerificationCodeForDisable">
                      {{ isButtonDisabled ? `Wait ${formattedTime}` : "Send" }}     
                    </v-btn>
                </v-col>
              </v-row>

              <!-- Verification code and Verify button on one line -->
              <v-row align="center">
                <v-col cols="9">
                    <v-text-field
                      label="Enter verification code"
                      v-model="emailVerificationCode"
                      :rules="emailVerificationCodeRules"
                      outlined
                      dense
                      maxlength="6"
                      counter="6"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <v-btn 
                    color="success" 
                    :disabled="!isEmailVerificationCodeValid()" 
                    @click="disabledEmailAuthentication">
                    Disable & Verify 
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import profileAPI from "@/services/profileAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import authAPI from "@/services/authAPI.js";
export default {
  data() {
    return {
      isLoading: false,
      authenticatorEnabled: false,
      emailEnabled: false,
      loader: "bars",
      verificationCode: "",
      deviceList: [],
      partyAuthenticationDetail: {}, // Holds the response data
      authenticationId: "",
      fromDate: "",
      showVerification: false,
      showAppLink: false,
      disabledGooglAuth: false,
      showEmailVerification: false,
      disabledEmailAuth: false,
      emailAuthenticationId: "",
      emailEnabledInApi: false,
      googleEnabledInApi: false,
      verificationCodeRules: [
        (v) => !!v || "Verification code is required.",
        (v) => /^[0-9]+$/.test(v) || "Verification code must be numeric.",
        (v) => v.length === 6 || "Verification code must be exactly 6 digits.",
      ],
      emailAddress: '',
      emailVerificationCode: '',
      emailVerificationCodeRules: [
        (v) => !!v || "Verification code is required.",
        (v) => /^[0-9]+$/.test(v) || "Verification code must be numeric.",
        (v) => v.length === 6 || "Verification code must be exactly 6 digits.",
      ],
      emailAuthenticationMsg: false,
      emailAuthenticationMsgDetal: "",
      countdown: 300, // 5 minutes in seconds
      isButtonDisabled: false,
      intervalId: null,
    };
  },
  computed: {
    isVerificationCodeValid() {
      return this.verificationCodeRules.every((rule) => rule(this.verificationCode) === true);
    },

    formattedTime() {
      let minutes = Math.floor(this.countdown / 60);
      let seconds = this.countdown % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  },
  components: {
    Loading,
  },
  watch: {
    options: {
      handler() {
        this.getUserAccountVerificationDetail();
        this.verifyGoogleAuthenticationCode();
        this.disabledGoogleAuthentication();
        this.sendEmailVerificationCode();
        this.verifyEmailAuthenticationCode();
        this.disabledEmailAuthentication();
        this.sendEmailVerificationCodeForDisable();
      },
      deep: true,
    },
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail")) || {}; // Safeguard in case of null
    this.getUserAccountVerificationDetail(); // Fetch the data
  },

  methods: {
    isEmailVerificationCodeValid() {
      // Ensure the rules are properly evaluated against the emailVerificationCode
      return this.emailVerificationCodeRules.every((rule) => rule(this.emailVerificationCode) === true);
    },
    
    verifyEmailCode() {
      // Logic for verifying the code
      console.log('Verification code entered:', this.emailVerificationCode);
    },

    async sendEmailVerificationCode() {
      this.isLoading = true;
      // Logic for submitting the email address
      console.log('Email submitted:', this.emailAddress);
      let sendEmailVerificationPayload = {
        username: this.emailAddress,
      };
      try {
        const response = await profileAPI.sendEmailVerificationCode(sendEmailVerificationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail || "Failed to send email! Please contact customer service.",
          color: "success",
        });
        await this.getUserAccountVerificationDetail();
        this.showEmailVerification = true;
        this.disabledEmailAuth = false;
        this.emailEnabled = true;
        // Start the countdown when the button is clicked
        this.startCountdown();
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to send email! Please contact customer service.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }
        this.showEmailVerification = true;
        this.disabledEmailAuth = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });
        this.isLoading = false;
      }
    },

    async sendEmailVerificationCodeForDisable() {
      this.isLoading = true;
      // Logic for submitting the email address
      console.log('Email submitted:', this.emailAddress);
      let sendEmailVerificationPayload = {
        username: this.emailAddress,
        isDisabled : true
      };
      try {
        const response = await profileAPI.sendEmailVerificationCode(sendEmailVerificationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail || "Failed to send email! Please contact customer service.",
          color: "success",
        });
        await this.getUserAccountVerificationDetail();
        this.showVerification = false;
        this.disabledEmailAuth = true;
        this.emailEnabled = false;
        // Start the countdown when the button is clicked
        this.startCountdown();
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to send email! Please contact customer service.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }
        this.disabledEmailAuth = true;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });
        this.isLoading = false;
      }
    },

    async verifyEmailAuthenticationCode() {
      this.isLoading = true;
      let sendVerificationPayload = {
        verificationCode: this.emailVerificationCode,
      };

      try {
        const response = await profileAPI.verifyEmailAuthenticationCode(sendVerificationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail || "Verification completed successfully!",
          color: "success",
        });
        await this.getUserAccountVerificationDetail();
        // this.showVerification = false;
        // this.showEmailVerification = false;
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to verify the code. Please try again.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }

        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });
        this.isLoading = false;
      }
    },

    async getUserAccountVerificationDetail() {
      this.isLoading = true;
      try {
        const response = await profileAPI.getUserAccountVerificationDetail();
        this.partyAuthenticationDetail = response.partyAuthenticationDetail;
        console.log("PartyAuthenticationDetail fetched successfully:");
        this.isLoading = false;

        // Initialize default states
        this.googleEnabledInApi = false;
        this.emailEnabledInApi = false;
        this.showAppLink = false;
        this.disabledGooglAuth = false;
        this.disabledEmailAuth = false;
        this.showEmailVerification = false;
        
        // Set states based on enabledTwoFactor
        this.authenticatorEnabled = this.partyAuthenticationDetail.enabledTwoFactor === "Y";
        if (this.authenticatorEnabled) {
          this.googleEnabledInApi = true;
          this.showAppLink = true;
        }

        // Set states based on enabledTwoFactorEmail
        this.emailEnabled = this.partyAuthenticationDetail.enabledTwoFactorEmail === "Y";
        if (this.emailEnabled) {
          this.emailEnabledInApi = true;
          this.showAppLink = this.authenticatorEnabled; // Show app link only if Google Auth is also enabled
        }
        if(this.partyAuthenticationDetail.emailAuthenticationMsg){
          console.log("emailAuthenticationMsg: "+this.partyAuthenticationDetail.emailAuthenticationMsg);
          this.emailAuthenticationMsg = true;
          this.emailAuthenticationMsgDetal = this.partyAuthenticationDetail.emailAuthenticationMsg;
        }else{
          this.emailAuthenticationMsg = false;
        }

        // // Automatically set the authenticatorEnabled state as a boolean
        // this.authenticatorEnabled = this.partyAuthenticationDetail.enabledTwoFactor === "Y";
        // if(this.partyAuthenticationDetail.enabledTwoFactor === "Y"){
        //   this.googleEnabledInApi = true;
        //   this.showAppLink = true;
        //   this.disabledGooglAuth = false;
        //   this.disabledEmailAuth = false;
        //   this.emailEnabledInApi = false;
        // }else{
        //   this.googleEnabledInApi = false;
        //   this.showAppLink = false;
        //   this.disabledGooglAuth = false;
        //   this.disabledEmailAuth = false;
        //   this.emailEnabledInApi = false;
        // }
        // this.emailEnabled = this.partyAuthenticationDetail.enabledTwoFactorEmail == "Y";
        // if(this.partyAuthenticationDetail.enabledTwoFactorEmail === "Y"){
        //   this.emailEnabledInApi = true;
        //   if(this.partyAuthenticationDetail.enabledTwoFactor === "Y"){
        //     this.showAppLink = true;
        //   }else{
        //     this.showAppLink = false;
        //   }
        //   this.disabledGooglAuth = false;
        //   this.disabledEmailAuth = false;
        //   this.googleEnabledInApi = false;
        // }else{
        //   this.emailEnabledInApi = false;
        //   if(this.partyAuthenticationDetail.enabledTwoFactor === "Y"){
        //     this.showAppLink = true;
        //   }else{
        //     this.showAppLink = false;
        //   }          this.disabledEmailAuth = false;
        //   this.disabledGooglAuth = false;
        //   this.googleEnabledInApi = false;
        // }

        this.authenticationId = this.partyAuthenticationDetail.authenticationId;
        this.emailAuthenticationId = this.partyAuthenticationDetail.emailAuthenticationId;
        this.emailAddress = this.partyAuthenticationDetail.userLoginId;
      } catch (error) {
        console.error("Error fetching PartyAuthenticationDetail list:", error);
        if (error.response) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
          console.error("Network or other error:", error.message);
        }
      }
    },

    async verifyGoogleAuthenticationCode() {
      this.isLoading = true;
      let sendVerificationPayload = {
        verificationCode: this.verificationCode,
      };

      try {
        const response = await profileAPI.verifyGoogleAuthenticationCode(sendVerificationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail || "Verification completed successfully!",
          color: "success",
        });
        await this.getUserAccountVerificationDetail();
        this.showVerification = false;
        this.showEmailVerification = false;
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to verify the code. Please try again.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }

        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });
        this.isLoading = false;
      }
    },

    async handleAuthenticatorToggle(newValue) {
      console.log(this.googleEnabledInApi+ " :googleEnabledInApi & Switch toggled to: ", newValue);
      if (!newValue) {
        // If the switch is toggled off, call the API to disable Google authentication
        this.showVerification = false;
        this.showEmailVerification = false;
        this.disabledEmailAuth = false;
         if(newValue !== this.googleEnabledInApi){
          this.disabledGooglAuth = true;  // Hide email authentication as disabled
        }else {
          this.disabledGooglAuth = false;  // Mark email authentication as disabled
        }
      } else {
        // this.showVerification = true;
        this.disabledGooglAuth = false;
        this.showEmailVerification = false;
        this.disabledEmailAuth = false;
        if(newValue !== this.googleEnabledInApi){
          this.showVerification = true;  // Hide email authentication as disabled
          // Case: Email authentication is not enabled yet
          this.$root.$emit("SHOW_SNACKBAR", {
        text: "Please enter the verification code to enable/disable Two-Factor Authentication using the app.",
            color: "info",
          });
        }else {
          this.showVerification = false;  // Mark email authentication as disabled
        }
      }
    },

    async handleEmailAuthenticatorToggle(newValue) {
      console.log(this.emailEnabledInApi+ " :emailEnabledInApi & Switch toggled to: ", newValue);
      if (!newValue) {
        // If the switch is toggled off, handle disabling logic
        this.disabledGoogleAuth = false; // Allow Google authentication to be enabled
        this.showVerification = false;  // Hide verification UI
        this.showEmailVerification = false; // Hide email verification UI
        if(newValue !== this.emailEnabledInApi){
          this.disabledEmailAuth = true;  // Hide email authentication as disabled
        }else {
          this.disabledEmailAuth = false;  // Mark email authentication as disabled
        }
      } else {
        // If the switch is toggled on, handle enabling logic
        this.showVerification = false;  // Reset any ongoing verification UI
        this.disabledGoogleAuth = false; // Keep Google authentication enabled
        this.showEmailVerification = true; // Show the email verification UI
        this.disabledEmailAuth = false;  // Enable email authentication
        if(newValue !== this.emailEnabledInApi){
          this.showEmailVerification = true;  // Hide email authentication as disabled
          // Case: Email authentication is not enabled yet
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please enter the verification code to enable/disable Two-Factor Email Authentication using the email inbox.",
            color: "info",
          });
        }else {
          this.showEmailVerification = false;  // Mark email authentication as disabled
        }
      }
    },

    async disabledGoogleAuthentication() {
      this.isLoading = true;
      let disabledGoogleAuthenticationPayload = {
        authenticationId: this.authenticationId,
        verificationCode: this.verificationCode
      };

      try {
        const response = await profileAPI.disabledGoogleAuthentication(disabledGoogleAuthenticationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.messageDetail || "Two Factor Authentication APP Disabled.!",
          color: "success",
        });
        // this.authenticatorEnabled = 'false';
        await this.getUserAccountVerificationDetail();
        // this.isLoading = false;
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to verify the code. Please try again.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }

        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });  
        this.disabledGooglAuth = true;
        this.disabledEmailAuth = false;
        this.isLoading = false;
      }
    },

    async disabledEmailAuthentication() {
      this.isLoading = true;
      let disabledEmailAuthenticationPayload = {
        authenticationId: this.emailAuthenticationId,
        verificationCode: this.emailVerificationCode
      };

      try {
        const response = await profileAPI.disabledGoogleAuthentication(disabledEmailAuthenticationPayload);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Two Factor Email Authentication Disabled.!",
          color: "success",
        });
        // this.authenticatorEnabled = 'false';
        await this.getUserAccountVerificationDetail();
        // this.isLoading = false;
      } catch (error) {
        // Safely retrieve the error message from the API response
        let errorMessage = "Failed to verify the code. Please try again.";
        if (error.data && error.data.messageDetail) {
          errorMessage = error.data.messageDetail;
        }

        this.$root.$emit("SHOW_SNACKBAR", {
          text: errorMessage,
          color: "error",
        });  
        this.disabledGoogleAuth = false; // Allow Google authentication to be enabled
        this.showVerification = false;  // Hide verification UI
        this.showEmailVerification = false; // Hide email verification UI
        this.isLoading = false;
      }
    },

    verifyCode() {
      if (this.verificationCode) {
        alert(`Verification code "${this.verificationCode}" submitted!`);
        // Add actual verification logic here
      } else {
        alert("Please enter a verification code.");
      }
    },
    startCountdown() {
      this.isButtonDisabled = true;
      this.countdown = 300; // Reset timer to 5 minutes

      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.isButtonDisabled = false;
          clearInterval(this.intervalId); // Stop the timer
        }
      }, 1000);
   }
  },
};
</script>

<style scoped>
.two-factor-auth {
  padding: 20px;
}

button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

.app-download-buttons {
  display: flex;
  gap: 10px;
}

.qr-code-container img {
  width: 150px;
  height: 150px;
  margin: 10px 0;
}

.spacer {
  margin-bottom: 10px;
}

.v-slide-group__content {
  border-bottom: 5px black solid;
  border-bottom-width: thin;
}

.tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    bottom: 125%; /* Position above the image */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .text-info {
  color: SlateBlue;
}
</style>
